const PageCategory: React.FC<{
  category: string[];
}> = ({ category }) => {
  const withSlash = category.join('/').split(/(\/)/g);

  return (
    <small
      className="micro font-medium text-secondary flex gap-4"
      style={{ textTransform: 'uppercase' }}
    >
      {withSlash.map((text, index) => (
        <span key={index}>{text}</span>
      ))}
    </small>
  );
};

export default PageCategory;
